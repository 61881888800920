export const AppConfig = {
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL ?? "",
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY ?? "",
  API_URL: process.env.REACT_APP_API_URL ?? "",
  Auth_CODE: process.env.REACT_APP_AUTH_CODE ?? "",
  STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "",
  KNOW_NOW_IP_API: process.env.REACT_APP_KNOW_NOW_IP_API ?? "",
  KNOW_NOW_MARKET_API: process.env.REACT_APP_KNOW_NOW_MARKET_API ?? "",
  REPORT_API_URL: process.env.REACT_APP_REPORT_API_URL ?? "",
  APP_URL: process.env.REACT_APP_APP_URL ?? "",
};
